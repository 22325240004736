<template>
  <div class="transition ease-in-out font-arial text-lg w-full text-white bg-hi-purple flex flex-col justify-center items-center rounded-30 shadow-lg p-5 cursor-pointer select-none" :class="{'transition ease-in-out bg-new-lightGrey' : inactive }">
    <div v-if="!loading">{{label}}</div>
      <loadingSVG v-else />
    <div v-if="info" class="text-xs" :class="{'hidden' : loading}">{{info}}</div>
  </div>
</template>

<script>
import loadingSVG from '../assets/svg/loading.vue';

export default {
  name: 'customButton',
  props: ['label', 'info', 'inactive', 'loading'],
  components: { loadingSVG },
};
</script>
